@charset "utf-8";

/* ==============================
   Small desktop
   ============================== */

@media only screen and (max-width: 1366px) {	
    
    .full-wrapper{
        margin-left: 30px;
        margin-right: 30px;
    }    
    .inner-nav ul li{
        margin-left: 32px;
    }
    .mn-has-multi{
        padding: 7px;
    }
    .mn-sub-multi {
        width: 196px !important;
    }
    .inner-nav ul li .mn-sub li a{
        padding: 10px 14px;
    }
}
@media only screen and (max-width: 1200px) {
    
    .inner-nav ul{
        font-size: 16px;
    }	
    .inner-nav ul li{
        margin-left: 23px;
    }
    .hs-line-1{
        font-size: 72px;
    }
    .hs-line-2{
        font-size: 68px;
    }
    .hs-line-12{
        font-size: 70px;
    }
    .hs-line-13{
        font-size: 78px;
    }
    .form input[type="text"].input-lg,
    .form input[type="email"].input-lg,
    .form input[type="number"].input-lg,
    .form input[type="url"].input-lg,
    .form input[type="search"].input-lg,
    .form input[type="tel"].input-lg,
    .form input[type="password"].input-lg,
    .form input[type="date"].input-lg,
    .form input[type="color"].input-lg,
    .form select.input-lg{
        height: 51px;
        font-size: 18px;
    }
    .btn-mod.btn-large{
        padding: 12px 35px 14px;
        font-size: 14px;
    }
    .alt-service-grid{
        margin-top: -40px;
    } 
    .alt-service-item{
        margin-top: 40px;
        padding-left: 48px;
    }
    .alt-services-title{
        font-size: 18px;
        margin-bottom: 5px;
    }
    .alt-services-image{
        margin-top: 10px;
    }
    
    .work-grid-5 .work-item{
        width: 25%;
    }
    
    .mod-breadcrumbs-mini{
        text-align: right;
    }
    
}
@media only screen and (max-width: 1199px) {
    
    .work-title{
        font-size: 18px;
        letter-spacing: 0;
    }
    .tpl-alt-tabs{
        font-size: 13px;
        letter-spacing: 0.2em;
    }
    .tpl-alt-tabs > li{
        width: 170px;
    }
    
    .split-section-wrapper{
        padding: 0 50px;
    }
    
}

/* ==============================
   Tablet landskape
   ============================== */

@media only screen and (max-width: 1024px) {
    
    .main-nav,
    .main-nav.small-height,
    .nav-logo-wrap .logo,
    .nav-logo-wrap .logo.small-height,
    .mobile-cart,
    .mobile-cart.small-height,
    .mobile-nav,
    .mobile-nav.small-height{
        line-height: 70px !important;
    }
    .scroll-down-alt{
        width: 62px;
        height: 62px;
        line-height: 52px;
        margin-left: -31px;
        font-size: 15px;
    }
    
    .hs-line-2{
        font-size: 60px;
    }
    .hs-line-7{
        font-size: 56px;
    }
    .hs-line-9{
        font-size: 71px;
    } 
    .float-images-1{
        top: 12.8vw;
    }
    .team-item-wrap{
        padding: 52px 36px 15px 36px;
    }
    .benefit-item{
        width: 300px;
        padding: 15px 32px;
    }

    .page-section{
        padding: 120px 0;
        
    }
    .page-section, .home-section, .small-section{
        background-attachment: scroll;
    }
    
    .owl-pagination{
        bottom: 25px;
    }  
    
    .lp-item{
        width: 50%;
    }
    .lp-item:nth-child(4n + 5) { 
        clear: none; 
    }
    .lp-item:nth-child(2n + 3) {        
        clear:left;
    }
    .lp-title, .lp-author{
        font-size: 13px;
    }
    
    .fa-examples > li{
        width: 33.3%;
    }

}
  
@media only screen and (max-width: 992px) {
    
    .split-background{
        width: 32%;
    }    
    .owl-prev,
    .owl-next{
        height: 37px;
        font-size: 37px;
    }
    .owl-prev{
        left: 10px;
    }
    .owl-next{
        right: 10px;
    }
    
    .mod-breadcrumbs:not(.align-center):not(.mod-breadcrumbs-mini) {
        text-align: left !important;
    }
    
    .tpl-alt-tabs > li{
        width: 200px;
    }
      
    .split-section-headings,
    .split-section-headings.left,
    .split-section-headings.right,
    .split-section-content,
    .split-section-content.left,
    .split-section-content.right{
        float: none !important;
        width: auto;
    }
    
    .split-section-headings .section-title{
        font-size: 35px;
        letter-spacing: 5px;
    }
    
    .banner-text{
        text-align: center;
    } 
    
    .banner-image{
        margin: 40px 0;
    }
    .team-item-wrap{
        padding: 52px 10px 15px 10px;
    }
    .team-item-name{
        font-size: 18px;
    }
    
    .features-icon{
        font-size: 64px;
    } 
    .features-title{
        font-size: 20px;
    }
    .features-descr{
        font-size: 14px;
    }
    .alt-features-grid{
        margin-top: -30px;
        margin-bottom: 30px;
    } 
    .alt-features-item{
        margin-top: 30px;
    }
    .work-item,
    .work-grid-3 .work-item,
    .container .work-grid-3 .work-item,
    .work-grid-5 .work-item{
        width: 49.9%;
    }      
    .countdown > li{
        margin: 0 20px;
    }
    .countdown-number{
        width: 74px;
        height: 74px;
        font-size: 24px;
        line-height: 64px;
    }
    .countdown-number > b{
        bottom: 18px;
    }   
    
}
  
  
/* ==============================
   Tablet Portrait
   ============================== */
  
@media only screen and (max-width: 768px) {

    .hs-wrap{
        max-width: 400px;
    }
    .hs-line-2{
        font-size: 50px;
    }
    .hs-line-4{
        font-size: 20px;
    }
    .hs-line-7{
        font-size: 64px;
    }
    .float-images-1{;
        top: 27vw;
        width: 18vw;
    }
    .float-images-2{;
        width: 18vw;
    }
    .float-images-3{
        bottom: 29.5vh;
        width: 13vw;
    }
    .float-images-4{;
        bottom: 15vh;
        width: 18vw;
    }
    .page-section{
        padding: 80px 0;
    }    
    .small-section{
        padding: 50px 0;
    }
    .small-title{
        font-size: 18px;
    }
    
    .owl-pagination{
        bottom: 17px;
    }  
    
    .tpl-minimal-tabs > li{
        padding: 0 5px;
    }    
        
    .benefit-item{
        width: 264px;
    }
    .alt-service-grid{
        margin-top: -30px;
    }
    .alt-service-item{
        margin-top: 30px;
    }

    .works-filter a{
        margin: 4px;
    }    
    .blog-item{
        margin-bottom: 50px;
    }
    .blog-item-title{
        font-size: 28px;
    }
    .blog-item-title .small{
        font-size: 15px;
    }
    .blog-media{
        margin-bottom: 15px;
    }
    .blog-item-body .small{
        font-size: 13px;
    }
    .google-map > iframe{
        height: 300px;
    }    
    .fm-logo-wrap{
        top: 17px;
        left: 17px;
    }
    .fm-button{
        top: 17px;
        right: 17px;
    }

}

@media only screen and (max-width: 767px) {
    
    .nav-logo-wrap .logo{
        max-width: 150px;
    }    
    .fixed-height-small{
        height: 300px;
        min-height: 300px;
    }
    .fixed-height-medium{
        height: 350px;
        min-height: 350px;
    }
    .fixed-height-large{
        height: 400px;
        min-height: 400px;
    }
    .hs-wrap{
        max-width: 320px;
        padding-top: 27px;
        padding-bottom: 33px;
    }
    .hs-line-1{
        font-size: 36px;
    }
    .hs-line-2{
        font-size: 48px;
    }    
    .hs-line-3{
        font-size: 16px;
    }
    .hs-image{
        display: none;
    }
    
    .hs-wrap-1{
        max-width: 240px;
    }    
    .hs-line-5{
        font-size: 36px;
    }
    .hs-line-6{
        font-size: 20px;
    }
    .hs-line-7{
        font-size: 50px;
    }
    
    .hs-line-9{
        font-size: 48px;
    } 
    
    .hs-line-11{
        font-size: 24px;
    }    
    .hs-line-12{
        font-size: 44px;
    }
    .hs-line-13{
        font-size: 60px;
    }
    .hs-line-14{
        font-size: 24px;
    }
    .hs-line-15{
        font-size: 32px;
    }
    .team-item{
        max-width: 370px;
        margin-left: auto;
        margin-right: auto;
    }
    .benefit-item{
        width: 230px;
        padding: 0;
    }    
    .benefit-item:hover:after{
        display: none;
    }       
    .wide-heading{
        font-size: 32px;
        letter-spacing: 2px;
    }    
    .works-filter a{
        margin: 4px;
        width: 46%;
    }
    .works-filter a.active:after,
    .works-filter a:hover:after{
        transform: scaleX(0);
    }
    .work-item,
    .work-grid-2 .work-item,
    .work-grid-3 .work-item,
    .container .work-grid-3 .work-item,
    .work-grid-5 .work-item{
        width: 100%;
    }   
    .newsletter-label-icon{
        font-size: 72px;  
    }
    .newsletter-label-text{
        margin-top: 20px;
        font-size: 32px;
    }    
    blockquote p{
        font-size: 20px;
    }    
    blockquote footer{
        margin-top: 0;
        font-size: 12px;
    }
    .banner-content{
        text-align: center;
    } 
    .banner-image{
        text-align: center;
    }    
    .cf-left-col,
    .cf-right-col{
        width: auto;
        margin-right: 0;
        float: none;
    }    
    .section-more,
    .section-more.left,
    .section-more.right{
        float: none !important;
    }    
    .big-icon{
        width: 60px;
        height: 60px;
        line-height: 52px;
        font-size: 24px;
        margin-bottom: 0;
    }
    .blog-slide-info{
        padding: 10px;
    }
    .blog-slide-title{
        font-size: 12px;
    }
    .blog-slide-data{
        display: none;
    }       
    .countdown > li{
        margin: 0 10px 20px;
    }
    .countdown-number{
        width: 44px;
        height: 44px;
        font-size: 20px;
        line-height: 44px;
    }
    .countdown-number > b{
        bottom: -20px;
        font-size: 9px;
        color: rgba(255,255,255, .85);
    } 
    .text-mobile{
        font-size: 110%;
        line-height: 1.2;
    }
    
}


/* ==============================
   Phone
   ============================== */
  
@media only screen and (max-width: 575px) { 
    .form input.newsletter-field {
        display: block;
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }
    .form .newsletter-button {
        display: block;
        width: 100%;
    }
}
    
@media only screen and (max-width: 480px) {
    
    .full-wrapper{
        margin-left: 20px;
        margin-right: 20px;
    }
    .container{
        padding-left: 20px;
        padding-right: 20px;
    }
    .float-images-1{;
        top: 32vw;
        left: 7vw;
        width: 25vw;
    }
    .float-images-2{;
        width: 25vw;
    }
    .float-images-3{
        bottom: 22vh;
        right: 17vw;
        width: 18vw;
    }
    .float-images-4{;
        bottom: 8vh;
        width: 25vw;
    }
    .lead{
        font-size: 120%;
    }
    .lead-alt{
        font-size: 32px;
    }
    blockquote{
        margin-bottom: 2em;
    }
    .blog-item-body blockquote p:before{
        display: block;
        margin-top: 1em;
        position: static;
        top: 0;
        left: 0;
        font-size: 2.58em;
        line-height: 0;
        opacity: 1;
    }
    .section-title{
         font-size: 32px;
    }
    .banner-heading{
        font-size: 32px;
    }
    .call-action-1-heading{
        font-size: 32px;
    }
    .form input[type="text"].input-md,
    .form input[type="email"].input-md,
    .form input[type="number"].input-md,
    .form input[type="url"].input-md,
    .form input[type="search"].input-md,
    .form input[type="tel"].input-md,
    .form input[type="password"].input-md,
    .form input[type="date"].input-md,
    .form input[type="color"].input-md,
    .form select.input-md,
    .form input[type="text"].input-lg,
    .form input[type="email"].input-lg,
    .form input[type="number"].input-lg,
    .form input[type="url"].input-lg,
    .form input[type="search"].input-lg,
    .form input[type="tel"].input-lg,
    .form input[type="password"].input-lg,
    .form input[type="date"].input-lg,
    .form input[type="color"].input-lg,
    .form select.input-lg{
        height: 41px;
        padding-bottom: 3px;
        font-size: 16px;
    }
    .form textarea.input-md,
    .form textarea.input-lg{
        padding-top: 6px;
        padding-bottom: 6px;
        font-size: 16px;
    }
    .btn-mod.btn-medium,
    .btn-mod.btn-large{
        padding: 8px 15px 10px 15px;
        font-size: 13px;
    }
    .hs-line-1{
        font-size: 36px;
    }
    .hs-line-2{
        font-size: 28px;
    }
    .hs-line-7{
        font-size: 32px;
        line-height: 1.1;
    }
    .hs-line-8{
        font-size: 12px;
    } 
    .hs-line-9{
        font-size: 42px;
    } 
    .hs-line-11{
        font-size: 20px;
    } 
    .hs-line-12{
        font-size: 32px;
    }
    .hs-line-13{
        font-size: 30px;
    }
    .hs-line-15{
        font-size: 24px;
    }    
    .nav-bar-compact{
        top: 0;
        left: 0;
    }    
    .page-section{
        padding: 70px 0;
    }
    .small-section{
        padding: 30px 0;
    }    
    .owl-pagination{
        bottom: 5px !important;
    }    
    .owl-prev, .owl-next{
        display: none;
    }
    .owl-page{
        padding-left: 8px;
        padding-right: 8px;
    }    
    .split-section-headings .section-title,
    .wide-heading{
        font-size: 24px;
    }    
    .section-heading,
    .sub-heading{
        font-size: 15px;
    }
    
    .tpl-minimal-tabs > li{
        padding: 0;
    }
    .tpl-minimal-tabs > li > a{
        padding: 6px 10px 5px;
    }
    .tpl-alt-tabs{
        font-size: 12px;
        letter-spacing: 0.1em;
    }
    .tpl-alt-tabs > li{
        width: 120px;
        font-size: 16px;
    }
    .alt-tabs-icon{
        font-size: 32px;
    }
    
    .work-container{
        padding: 30px 0;
    }     
    .count-number{
        font-size: 48px;
    }
    .works-filter{
        margin-bottom: 30px;
    }
    .works-filter a{
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }    
    .work-full-text{
        margin-top: 30px;
    }
    .work-full-title{
        font-size: 20px;
    }    
    .call-action-2-image-2{
        margin: 60px auto;
    }
    .alt-service-item{
        font-size: 16px;
    }
    .newsletter-label-text{
        font-size: 24px;
    }
    .footer-social-links a{
        width: 42px;
        height: 42px;
        line-height: 42px !important;
    }
    .footer-social-links a i,
    .footer-social-links a span[class^=icon]{
        line-height: 42px;
    }
    .blog-container{
        margin-top: 30px;
        margin-bottom: 30px;
    }    
    .blog-item-q p{
        font-size: 20px;
    }
    .pagination a{
        min-width: 32px;
        margin-right: 3px;
        padding: 6px 8px;
        font-size: 16px;
    }
    .comment-avatar{
        width: 30px;
        margin-top: 7px;
    }    
    .comment-item{
        font-size: 13px;
    }    
    .separ-space{
        display: block;
        height: 10px;
    }    
    .bot-menu-item{
        font-size: 16px;
    }    
    .google-map > iframe{
        height: 300px;
    }
    .tpl-tabs > li{
        display: block;
        float: none;
        width: 100%;
    }
    .tpl-tabs > li.active a{
        background: #f5f5f5 !important;
    }    
    .fm-menu-links a{
        font-size: 14px;
    }
    .fm-search{
        width: 200px;
    }
    
    .fa-examples > li{
        width: 50%;
    }
    .shopping-cart-table{
        font-size: 16px;
    }
    .shopping-cart-table-title{
        min-width: 150px; 
    }
    
}


/* ==============================
   Small Phone
   ============================== */
  
@media only screen and (max-width: 319px) {
    
      .nav-social-links{
          display: none;
      }
      
}


/* ==============================
   Max Height 767
   ============================== */

@media only screen and (max-height: 767px) {
    
    .split-image img{
        width: 80%;
    }
    
}


/* ==============================
   Max Height 480
   ============================== */

@media only screen and (max-height: 480px) {
    
    .float-images-1{;
        top: 13.3vw;
        left: 7vw;
        width: 12vw;
    }
    .float-images-2{;
        width: 12vw;
    }
    .float-images-3{
        bottom: 29vh;
        right: 9.8vw;
        width: 9vw;
    }
    .float-images-4{;
        bottom: 8vh;
        width: 12vw;
    }
    
}


/* ==============================
   Max Height 374
   ============================== */

@media only screen and (max-height: 374px) {
    
    .min-height-80vh,
    .min-height-90vh,
    .min-height-100vh{
        min-height: 374px;
    }
    
}


/* ==============================
   IOS Background Fix
   ============================== */
  
.mobile .page-section, .mobile .home-section, .mobile .small-section{
    background-attachment: scroll !important;
}

/*
 * iPad Pro
 */

@media only screen and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    
    html:not(.no-touch) .page-section, html:not(.no-touch) .home-section, html:not(.no-touch) .small-section{
        background-attachment: scroll !important;
    }
    .float-images-1{
        top: 27vw;
        left: 10vw;
        width: 20vw;
    }
    .float-images-2{
        position: absolute;
        bottom: 0;
        left: 3.125vw;
        width: 20vw;
    }
    .float-images-3{
        bottom: 32vh;
        right: 14vw;
        width: 15vw;
    }
    .float-images-4{
        position: absolute;
        bottom: 18vh;
        right: 3.125vw;
        width: 20vw;
    }
    
}

/* ==============================
   Internet Explorer Fix
   ============================== */
@media all and (-ms-high-contrast:none){
     
    .min-height-80vh{
        height: 80vh;
    } 
    .min-height-90vh{
        height: 90vh;
    } 
    .min-height-100vh{
        height: 100vh;
    }
    .fixed-height-large{
        height: 800px;
    } 
    .fixed-height-medium{
        height: 700px;
    } 
    .fixed-height-small{
        height: 600px;
    }
     
}


/* ==============================
   Print Styles Fix
   ============================== */
  
@media print {
    
    *{
        color: #000 !important;
    }
    
    .main-nav{
        display: none;
    }
    .buy-button{
        display: none;
    }
    img{
        display: block;
    }    
}